

import { Checkbox, Group, NumberFormatter, Space, Text, ThemeIcon, Tooltip, } from '@mantine/core';
import { HitsPerPage, InstantSearch, Pagination, SearchBox, useHits, InstantSearchProps, Configure, useSortBy } from 'react-instantsearch';

import { TagBadge } from './tag_badge';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { useContext, useState } from 'react';
import { MyUserDataContext } from '../contexts';
import DataTable, { TableColumn } from 'react-data-table-component';

import { useSessionStorage } from '@mantine/hooks';
import { Order, OrderStatusCode, } from '../types';
import { Timestamp } from 'firebase/firestore';

import { IconMoneybag, IconReceipt, IconReceiptRefund, IconWriting } from '@tabler/icons-react';

//typesense

interface InitialState {
    query?: string;
    page?: number;
}

const TableDisplay = ({ setSelectedRows, selectedRows, renderTags, ActionsComponent, navigateDetails, indexName, ...props }: any) => {
    const { items } = useHits();


    const { refine } = useSortBy(
        {
            items: [
                { label: 'Created (asc)', value: `${indexName}/sort/created:asc` },
                { label: 'Created (desc)', value: `${indexName}/sort/created:desc` },
            ]


        }
    );

    const handleChange = ({ selectedRows }: any) => {
        console.log("selectedRows  =====>>>", selectedRows);
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        if (setSelectedRows) {
            setSelectedRows(selectedRows);
        }
    };


    const columns: TableColumn<Order>[] = [

        {
            // name: "Type",
            cell: (row: Order) => {

                let icons = [];

                if (row.type === "subscription") icons.push(
                    <Tooltip label="Subscription" transitionProps={{ transition: 'scale', duration: 500 }}>
                        <ThemeIcon variant="white" color="green">
                            <IconReceipt size={16} />
                        </ThemeIcon>
                    </Tooltip>
                )
                else if (row.type === "redemption") icons.push(
                    <Tooltip label="Redemption" transitionProps={{ transition: 'scale', duration: 500 }}>
                        <ThemeIcon variant="white" color="orange">
                            <IconReceiptRefund size={16} />
                        </ThemeIcon>
                    </Tooltip>
                )

                if (row.tags?.paid) icons.push(

                    <Tooltip label="Paid" transitionProps={{ transition: 'scale', duration: 500 }}>
                        <ThemeIcon variant="white" color="yellow">
                            <IconMoneybag size={16} />
                        </ThemeIcon>
                    </Tooltip>
                )

                if (row.tags?.manual) icons.push(
                    <Tooltip label="Manual" transitionProps={{ transition: 'scale', duration: 500 }}>
                        <ThemeIcon variant="white" color="blue">
                            <IconWriting size={16} />
                        </ThemeIcon>
                    </Tooltip>

                )

                return (<>{icons}</>)
            },
            // sortable: true,
            width: "90px",
        },
        {
            name: "Name",
            id: "customerObj.name_en",
            selector: (row: Order) => {

                return row.customerObj?.name_en ?? ""
            },
            sortable: true,
            grow: 1,

        },
        {
            name: "Mobile",
            selector: (row: Order) => row.customerObj?.mobile ?? "",

            // sortable: true,
            grow: 1,



        },
        {
            name: "Civil ID",
            id: "customerObj.civilID",
            selector: (row: Order) => row.customerObj?.civilID ?? "",
            sortable: true,
            grow: 1,

        },

        {
            name: "Fund",
            id: "fund.name_en",

            selector: (row: Order) => row?.fund?.name_en,
            sortable: true,
        },
        {
            name: "Date",
            id: "created",
            cell: (row: Order) => {
                let created = row.created;

                // console.log(created);
                // console.log("Created: ", row.created);
                if (!(created instanceof Timestamp)) {
                    created = new Timestamp(created as any, 0);
                    row.created = created;
                }
                return `${created.toDate().toLocaleString("en-GB", { hour12: true }) ?? row.created}`
            },
            sortable: true,

        },

        {
            name: "Units",

            cell: (row: Order) => {
                return Number(row.units) === 0 ?
                    <Text size="sm" ta="right">
                        All Units
                    </Text> as any
                    :

                    <NumberFormatter value={Number(row.units)} thousandSeparator />
            },

            grow: 1,

        },
        {
            name: "Tags",
            wrap: true,
            // width: "150px",
            cell: (row: Order) => {
                if (!row.tags) return;

                return <TagBadge m={3} tag={row.tags["orderStatus"]} key="orderStatus" />

                /*
                                //loop through tags and create a badge for each
                                let tagsList: React.JSX.Element[] = [];
                                for (let tagKey of Object.keys(row.tags!).sort()) {
                                    let tag = row.tags![tagKey];
                
                                    // tagsList.push(<Badge size="xs" variant="filled" c={tag.bgColor} key={tagKey} style={{ backgroundColor: tag.bgColor, color: tag.color }}>{tag.title}</Badge>);
                                    tagsList.push(<TagBadge tag={tag} key={tagKey} />);
                                    // tagsList.push(" ");
                                }
                
                                return <>{tagsList}</> as any;
                */
            },
        },

        {
            name: "",
            cell: (row: any) => <>

                {<ActionsComponent orderObj={row} />}
            </>,

            grow: 0,

        }


    ];



    return <DataTable
        columns={columns}
        selectableRows={selectedRows}

        onSelectedRowsChange={handleChange}
        sortServer
        onSort={(column, sortDirection) => {
            if (column.id) {
                let sortByValue = `${indexName}/sort/${column.id}`
                if (sortDirection === "asc") {
                    sortByValue += `:asc`
                }
                else {
                    sortByValue += `:desc`
                }

                refine(sortByValue)
            }
        }}
        data={items.map((hitObj: any) => {

            return {
                ...hitObj,
                customerID: hitObj?.customerObj?.civilID,
            }
        })}
    />
}

interface OrdersInstantSearchProps {
    indexName: string,
    setSelectedRows?: any
    renderTags?: boolean
    ActionsComponent?: any
    navigateDetails?: any
    selectable?: boolean
    selectedRows?: any[]
    defaultFilter?: string
    allowShowAll?: boolean

    topElements?: JSX.Element
    additionalChildren?: JSX.Element
}
const OrdersInstantSearch = ({ indexName, selectable, setSelectedRows, selectedRows = [], navigateDetails, renderTags, ActionsComponent, topElements, additionalChildren, defaultFilter = "", allowShowAll = true }: OrdersInstantSearchProps) => {

    const [orderStatusFilter, setOrderStatusFilter] = useState<string | null>()
    const userData = useContext(MyUserDataContext);
    let customClaims = userData.firebaseTokenResult?.claims.customClaims as any;

    const handleRowsToShow = () => {


        const orderStatuses: string[] = []

        if (customClaims["rm"] === true || customClaims["kfh_rm"] === true || customClaims["kfh_oper"] === true) {
            orderStatuses.push(...[OrderStatusCode.need_slip, OrderStatusCode.need_signature, OrderStatusCode.need_form])

        }
        if (customClaims["cr"] === true) {
            orderStatuses.push(...[OrderStatusCode.review, OrderStatusCode.approval, OrderStatusCode.waiting_for_kyc])


        }
        if (customClaims["oper"] === true) {
            orderStatuses.push(...[OrderStatusCode.processing, OrderStatusCode.waiting_for_nav])

        }

        if (orderStatuses.length) {



            setOrderStatusFilter(`tags.orderStatus.code:[${orderStatuses.join()}]`)

        }
        else {
            setOrderStatusFilter(null)
        }

    }
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
            apiKey: userData.customSearchKey!, // Be sure to use a Search API Key
            nodes: [
                {
                    host: process.env.REACT_APP_TYPESENSE_SEARCH_HOST!,
                    port: 443,
                    protocol: 'https'
                },
            ],
        },
        additionalSearchParameters: {

            query_by: "customerObj,fund,tags,rm.name_en,rm.name_ar,rm.rm_id"
        }

    })
    const typesenseSearchClient = typesenseInstantsearchAdapter.searchClient;

    if (!indexName) {
        indexName = process.env.REACT_APP_TYPESENSE_ORDERS_COLLECTION!;
    }

    //save to local session
    const [initialUIStateForIndex, setInitialUIStateForIndex] = useSessionStorage<InitialState>({
        key: 'initialUIStateFor' + indexName,
        getInitialValueInEffect: false,
    });


    const onStateChange: InstantSearchProps['onStateChange'] = ({
        uiState,
        setUiState,
    }) => {
        // Custom logic
        // setUiState(uiState);
        // console.log("setting ui state", uiState);
        setInitialUIStateForIndex(uiState[indexName]);
    };

    // console.log("session for ", indexName, initialUIStateForIndex);

    // if (!initialUIStateForIndex)  {
    //     return <div></div>;
    // }


    return (
        <InstantSearch

            searchClient={typesenseSearchClient}
            indexName={indexName}
            initialUiState={{
                [indexName]: initialUIStateForIndex
            }}
            onStateChange={onStateChange}


        >
            <Configure

                filters={
                    `${orderStatusFilter ? orderStatusFilter : "" +
                        ((orderStatusFilter && defaultFilter?.length) ? `,` : "") +
                        defaultFilter || ""}`
                }

            />

            <Group justify="space-between" >

                {topElements}


                <SearchBox
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",

                        width: "80%",
                    }}
                    autoFocus placeholder='Search...'
                />

                {allowShowAll && <Checkbox
                    label="Show all orders"
                    defaultChecked={true}
                    onChange={(event) => {
                        if (!event.currentTarget.checked) {
                            handleRowsToShow()
                        }
                        else {
                            setOrderStatusFilter(null)
                        }
                    }

                    }
                />}




                {additionalChildren}

            </Group>

            <TableDisplay
                selectedRows={selectable ? selectedRows : null}
                setSelectedRows={selectable ? setSelectedRows : undefined}
                navigateDetails={navigateDetails}
                ActionsComponent={ActionsComponent}
                renderTags={renderTags}
                indexName={indexName}
            />

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    width: "100%",
                }}
            >

                <HitsPerPage
                    // style={{
                    //     display: "flex",
                    //     justifyContent: "flex-end",
                    //     background: "red",
                    //     width: "20%",
                    // }}
                    items={[
                        { label: '250', value: 250, },
                        { label: '100', value: 100 },
                        { label: '50', value: 50, default: true },
                    ]}
                />
            </div>
            <Space h="xl" />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",


                }}
            />

        </InstantSearch >



    )
}

export default OrdersInstantSearch