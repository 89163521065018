//make a component that displays history for a customer in a DataTable

import { Loader } from "@mantine/core";
import { DocumentData, orderBy, query, QueryOrderByConstraint } from "firebase/firestore";
import DataTable from "react-data-table-component";
import useFirestoreCollection from "../helpers/useFirestoreCollection";

const columns: any[] = [
    {
        name: 'Action',
        selector: (row: any) => row.msg,
        // grow: true,
    },
    {
        name: 'by',
        selector: (row: any) => row.by,
        // grow: true,
    },
    {
        name: 'Date',
        selector: (row: any) => new Date(row.date.seconds * 1000).toLocaleString("en-GB", { hour12: true }),
        sortable: true,
        // grow: true,
    },
    {
        name: 'Details',
        selector: (row: any) => row.details,
        // grow: false,
        wrap: true,
    },

];




export const History = (props: any) => {
    const historyQuery = query<DocumentData, QueryOrderByConstraint>(props.historyCollection, orderBy("date", "desc"));
    const { loading: historyLoading, data: historyData } = useFirestoreCollection(historyQuery, true);


    if (historyLoading) {
        return <Loader />
    } else {
        // console.log(status, rows);
        return <DataTable
            // paginationPerPage={50}
            dense={true}
            columns={columns}
            data={historyData ?? []}
        // pagination
        />
    }



}

