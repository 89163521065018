import React, { useContext, useEffect, useRef, useState } from "react";

import { addDoc, collection, doc, limit, query } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { Loader, Tabs } from "@mantine/core";

import { TagBadge } from "../../components/tag_badge";
import { History } from "../../components/history";
import { CustomerKYC } from "./customer_kyc";
import { CustomerInfo } from "./customer_info";
// import { Orders } from "./orders";
import { CustomClaims, Customer, CustomerFundData, DocType, Portfolio } from "../../types";
import { useFirestore } from "../../helpers/firebaseContext";
import useFirestoreDoc from "../../helpers/useFirestoreDoc";
import OrdersInstantSearch from "../../components/orders_instant_search";
import { RefreshButton, RefreshButtonRef } from "../../components/customer_instant_search";
import { RenderActions } from "../orders/orders";
import useFirestoreCollection from "../../helpers/useFirestoreCollection";
import PositionBadges from "../../components/position_badges";
import { MyUserDataContext } from "../../contexts";
import AskForConsent from "../../components/ask_for_consent";
import { useSessionStorage } from "../../helpers/useStorage";



//create CustomerInfo component
export default function CustomerMain(props: any) {
  const { id } = useParams();

  const db = useFirestore();
  const navigate = useNavigate();

  const refreshRef = useRef<RefreshButtonRef | null>(null);


  const [activeTab, setActiveTab] = React.useState<string | null>("kyc");
  // const [needsConsent, setNeedsConsent] = useState(true)
  const [lastViewedTabs, setLastViewedTabs] = useSessionStorage(`lastViewedTabs-${id}`, '{"positions":null}');
  const [metaTypes, setMetaTypes] = useState<DocType[]>([]);

  const userContext = useContext(MyUserDataContext);

  let userClaims = userContext.firebaseTokenResult?.claims.customClaims as CustomClaims;

  const customerDataDoc = doc(db, "customers", id as string);
  const { loading: customerLoading, data: customerData } = useFirestoreDoc<Customer>(customerDataDoc, true);
  //If it's a KFH RM user and the customer didn't consent to access RM data, show the consent page
  const needsConsent = userClaims["kfh_rm"] && !customerData?.agreements?.kfh_rm_access_consent

  // if (needsConsent) {
  //   return <AskForConsent customerData={customerData!} />
  // }

  let portfolioDataQuery = query(collection(db, "customers", id as string, "portfolios"),);
  let fundDataQuery = query(collection(db, "customers", id as string, "fund_data"));

  // if (needsConsent) {
  //   portfolioDataQuery = query(portfolioDataQuery, limit(0))
  //   fundDataQuery = query(fundDataQuery, limit(0))
  // }
  const { loading: portfoliosLoading, data: portfoliosData } = useFirestoreCollection(portfolioDataQuery);
  const { loading: fundDataLoading, data: fundData } = useFirestoreCollection(fundDataQuery);




  if (customerLoading || !customerData || portfoliosLoading || fundDataLoading) {
    return <Loader />
  }

  const castedPortfolioData = (portfoliosData as Portfolio[]);
  const castedFundData = fundData != undefined ? (fundData as CustomerFundData[]) : []

  const handleTabChange = (value: string | null) => {
    setActiveTab(value)
    if (value && !needsConsent) {
      if (value === "positions") {

        trackRmViews(value);
      }
    }
  }

  //create new subcollection under customer to track who viewed which tab was viewed and at what time with a 5 min cooldown
  const trackRmViews = (value: string) => {

    if (customerDataDoc) {


      const userId = userContext.user?.uid;

      if (userId) {
        const viewsCollection = collection(db, "customers", id as string, "rm_views");
        const currentDate = Date.now();
        const cooldown = 5 * 60 * 100



        //store to local storage and check when it waslastrsaved
        const lastViewedTabJson = JSON.parse(lastViewedTabs);

        const lastViewedTime: null | Date = lastViewedTabJson[value];
        //if the rm didn't view the tab in the last 5 min, create a new rm_view instance   
        if (!lastViewedTime || (lastViewedTime && (currentDate - new Date(lastViewedTime)?.getTime() > cooldown))) {

          const viewData = {

            email: userContext.user?.email,
            displayName: userContext.user?.displayName,
            userId: userId,
            tab: value,
            timestamp: new Date()
          }

          const updatedViewedTabs = { ...lastViewedTabJson, [value]: new Date() }
          setLastViewedTabs(JSON.stringify(updatedViewedTabs))
          addDoc(viewsCollection, viewData);

        }


      }
    }

  }


  return (
    <>
      {/* <Tabs defaultValue="kyc" value={tabValue} onChange={(value: any) => navigate(`/customers/${id}/${value}`)}> */}
      <Tabs onChange={handleTabChange} value={activeTab}>
        <Tabs.List>
          <Tabs.Tab value="positions"
            // Start of Selection
            rightSection={customerData?.agreements?.kfh_rm_access_consent && <PositionBadges fundData={castedFundData} portfolioData={castedPortfolioData} />}
          >Positions</Tabs.Tab>
          {/* <Tabs.Tab value="fundsTransactions"
          >Fund Transactions</Tabs.Tab> */}
          <Tabs.Tab
            value="kyc"
            rightSection={<TagBadge tag={customerData?.tags?.kycStatus} />}
          >
            KYC
          </Tabs.Tab>
          <Tabs.Tab value="history">History</Tabs.Tab>
          <Tabs.Tab value="orders">Orders</Tabs.Tab>
          {/* <Tabs.Tab value="transactions">Transactions</Tabs.Tab> */}
        </Tabs.List>

        <Tabs.Panel value="positions">

          {needsConsent ? <AskForConsent customerData={customerData} /> : <CustomerInfo fundData={castedFundData} portfoliosData={castedPortfolioData} customerData={customerData!} />}
          {/* <CustomerInfo fundData={castedFundData} portfoliosData={castedPortfolioData} customerData={customerData!} /> */}
        </Tabs.Panel>
        {/* <Tabs.Panel value="fundsTransactions">
          <CustomerFundsTransactions fundData={castedFundData} customerData={customerData!} />
        </Tabs.Panel> */}
        <Tabs.Panel value="kyc">
          {/* Remove the consent for kyc tab (needed to access manual links) */}
          {<CustomerKYC metaTypes={metaTypes} setMetaTypes={setMetaTypes} customerData={customerData!} showButton={true} showKycVersion={true} showActiveLinks={true} showGeneratePDF={true} />}
          {/* <CustomerKYC metaTypes={metaTypes} setMetaTypes={setMetaTypes} customerData={customerData!} showButton={true} showKycVersion={true} showActiveLinks={true} showGeneratePDF={true} /> */}
        </Tabs.Panel>
        <Tabs.Panel value="orders">
          {needsConsent ? <AskForConsent customerData={customerData} /> : <>

            <OrdersInstantSearch

              selectable={false}

              renderTags={true}
              navigateDetails={(obj: any) =>
                navigate("/orders/" + obj.id)
              }
              allowShowAll={false}
              defaultFilter={`customerObj.id:=${id}`}
              ActionsComponent={RenderActions}
              indexName={process.env.REACT_APP_TYPESENSE_ORDERS_COLLECTION!}

              additionalChildren={<RefreshButton ref={refreshRef} />}



            />
          </>}

        </Tabs.Panel>

        <Tabs.Panel value="history">

          {needsConsent ? <AskForConsent customerData={customerData} /> : <History
            historyCollection={collection(
              db,
              "customers",
              id as string,
              "history"
            )}
          />}


        </Tabs.Panel>
      </Tabs>
    </>
  );
}
