
import { Card, Divider, Grid, Loader, Space, Text, Title, } from '@mantine/core'
import { collection, query, where } from 'firebase/firestore';
import { Fund } from '../../types';
import { useFirestore } from '../../helpers/firebaseContext';
import useFirestoreCollection from '../../helpers/useFirestoreCollection';
import UploadSubRed from './upload_sub_red';
import FundManagementCard from '../../components/fund_management_card';

const NavUpdateSettings = () => {


    const db = useFirestore();

    const fundCollection = collection(db, "funds");

    const fundsQuery = query(fundCollection, where("active", "==", true), where('type', '==', 'open_ended'));

    const { loading: fundsLoading, data: fundsData } = useFirestoreCollection(fundsQuery, true);


    if (fundsLoading
    ) {
        return <Loader />
    }

    return (
        <>
            <Grid columns={6} gutter="xs" mb="xs">
                {/* NAV Update - 2 cells */}
                <Grid.Col ta="center" span={2}>
                    <Title order={3}
                        style={{
                            fontSize: '1.5rem',
                            color: 'white',
                            textShadow: '1px 1px 2px rgba(0,0,0,0.2)',
                            backgroundImage: 'linear-gradient(to right, #4A90E2, #50E3C2)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}>
                        NAV Updates
                    </Title>
                </Grid.Col>
                <Divider orientation="vertical" />

                {/* NAV Approval - 1 cell (used 0.97 to handle the dividers) */}
                <Grid.Col ta="center" span={0.97}>
                    <Title order={3}
                        style={{
                            fontSize: '1.5rem',
                            color: 'white',
                            textShadow: '1px 1px 2px rgba(0,0,0,0.2)',
                            backgroundImage: 'linear-gradient(to right, #F5A623, #FF6B6B)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}>
                        Pending Approvals
                    </Title>
                </Grid.Col>
                <Divider orientation="vertical" />

                {/* Adding Dividend - 2 cells */}
                <Grid.Col ta="center" span={3}>
                    <Title order={3}
                        style={{
                            fontSize: '1.5rem',
                            color: 'white',
                            textShadow: '1px 1px 2px rgba(0,0,0,0.2)',
                            backgroundImage: 'linear-gradient(to right, #2ECC71, #1ABC9C)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}>
                        Dividend Additions
                    </Title>
                </Grid.Col>
            </Grid>
            {fundsData?.map((fundData) => {
                const fund = fundData as Fund;
                return <FundManagementCard fund={fund} />;
            })}

            <Space h="xl" />
            <Card shadow="sm" p="xl" radius="md" withBorder>
                <Text>Upload Sub/Red Transactions</Text>
                <UploadSubRed />
            </Card >
        </>
    )
}

export default NavUpdateSettings