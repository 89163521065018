
import { NavLink, Indicator } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { MyUserDataContext } from '../contexts';
import { ClaimType, KYCStatusCode, OrderStatusCode, ServiceStatusCode } from '../types';
import { collection, collectionGroup, onSnapshot, query, where } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { routes } from '../App';
import { useFirestore } from '../helpers/firebaseContext';





export interface NavigationBarType {

  to: string,
  showfor?: ClaimType[]
  icon: JSX.Element
  label: string,
}


export function MainLinks() {
  const db = useFirestore();
  const [pendingOrders, setPendingOrders] = useState(0)
  const [pendingServices, setPendingServices] = useState(0)
  const [pendingCustomers, setPendingCustomers] = useState(0)
  const userData = useContext(MyUserDataContext);
  let customClaims = userData.firebaseTokenResult?.claims.customClaims as any;

  useEffect(() => {

    const orderStatuses: string[] = []


    if (customClaims["rm"] === true) {
      orderStatuses.push(OrderStatusCode.need_slip, OrderStatusCode.need_form)

    }
    if (customClaims["cr"] === true) {
      orderStatuses.push(...[OrderStatusCode.review, OrderStatusCode.approval, OrderStatusCode.waiting_for_kyc])

    }
    if (customClaims["oper"] === true) {
      orderStatuses.push(...[OrderStatusCode.processing, OrderStatusCode.waiting_for_nav])

    }


    if (orderStatuses.length) {
      const orderQuerySnapshot = query(collectionGroup(db, "orders"), where('tags.orderStatus.code', 'in', orderStatuses))


      return onSnapshot(orderQuerySnapshot, (snapShot) => {

        setPendingOrders(snapShot.docs?.length || 0);
      })
    }
    /*
    useFirestoreCollection(configCollection)
    const { status, data: config } = useFirestoreCollectionData(configCollection, {
      idField: 'id', // this field will be added to the object created from each document
    });
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customClaims]);
  useEffect(() => {

    const serviceStauses: string[] = []


    if (customClaims["cr"] === true) {
      serviceStauses.push(...[ServiceStatusCode.authorized_signatory_approval, ServiceStatusCode.waiting_for_kyc])

    }

    if (serviceStauses.length) {
      const orderQuerySnapshot = query(collectionGroup(db, "services"), where('tags.serviceStatus.code', 'in', serviceStauses))


      return onSnapshot(orderQuerySnapshot, (snapShot) => {

        setPendingServices(snapShot.docs?.length || 0);
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customClaims]);

  useEffect(() => {


    const kycStatuses: string[] = []


    if (customClaims["cr"] === true) {

      kycStatuses.push(...[KYCStatusCode.review, KYCStatusCode.approval, KYCStatusCode.exec_approval])

    }

    if (kycStatuses.length) {
      const customersQuerySnapshot = query(collection(db, "customers"), where('tags.kycStatus.code', 'in', kycStatuses))

      return onSnapshot(customersQuerySnapshot, (snapShot) => {

        setPendingCustomers(snapShot.docs?.length || 0);
      })
    }
    /*
    useFirestoreCollection(configCollection)
    const { status, data: config } = useFirestoreCollectionData(configCollection, {
      idField: 'id', // this field will be added to the object created from each document
    });
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customClaims]);
  // const [active, setActive] = useState(0);

  const location = useLocation();

  return <div>
    <MyUserDataContext.Consumer>
      {(userData: any) => {
        let customClaims = userData.firebaseTokenResult.claims.customClaims as any;


        return routes.map((route, index) => {
          //do not show if any of these are missing
          if (!route.icon || !route.label || !route.to) return null;

          if (route.showfor) {
            let exists = false;

            for (const claimString in customClaims) {
              let claim = claimString as ClaimType;

              if (customClaims[claim] === true && route.showfor.includes(claim)) {
                exists = true;
                break;
              }
            }

            if (!exists) return null;
          }



          return <NavLink {...route} leftSection={
            route.to === "/orders" && pendingOrders ?
              <Indicator inline color='pink' label={pendingOrders} size={16}>
                {route.icon}
              </Indicator>
              :
              route.to === "/customers" && pendingCustomers ?
                <Indicator inline color='pink' label={pendingCustomers} size={16}>
                  {route.icon}
                </Indicator>
                :
                route.to === "/services" && pendingServices ?
                  <Indicator inline color='pink' label={pendingServices} size={16}>
                    {route.icon}
                  </Indicator> :
                  route.icon}

            style={{
              fontSize: "1rem",

              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"'
            }}
            key={route.label} component={Link} active={location.pathname.startsWith(route.to) && route.to !== "/"} color="teal" />
        });
      }}
    </MyUserDataContext.Consumer>

  </div>;
}