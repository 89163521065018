import React, { useState, useContext } from 'react';
import { Card, NumberInput, TextInput, Group, Button, Text, Grid, Divider, rem, Title } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { DateInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { arrayUnion, deleteField, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { useFirestore } from '../helpers/firebaseContext';
import { MyUserDataContext } from '../contexts';
import { dateFormater, parseDayFirst } from '../helpers/common';
import { Fund } from '../types';

const FundManagementCard: React.FC<{ fund: Fund }> = ({ fund }) => {
    //NAV States
    const [nav, setNav] = useState<number | string | undefined>(fund.nav_per_unit);
    const [navDate, setNavDate] = useState<Date | null>(null);
    const [navLoading, setNavLoading] = useState<boolean>(false);
    
    //Dividend States
    const [dividendPercentage, setDividendPercentage] = useState<number | string | undefined>();
    const [dividendDate, setDividendDate] = useState<Date | null>(null);
    const [dividendNotes, setDividendNotes] = useState<string>('');
    const [dividendLoading, setDividendLoading] = useState<boolean>(false);

    const db = useFirestore();
    const appContext = useContext(MyUserDataContext);

    const updateNav = (fundID: string, nav_per_unit: number, nav_date: Timestamp) => {

        setNavLoading(true);
        const id = notifications.show({
            loading: true,
            title: 'Saving new NAV for ' + fundID + '...',
            message: 'Give it a minute...',
            autoClose: false,
            withCloseButton: false,
        });

        const fundRef = doc(db, "funds", fundID);
        updateDoc(fundRef, {
            "new_nav_awaiting_approval": {
                "nav_per_unit": nav_per_unit,
                "nav_date": nav_date,
                "updatedBy": appContext?.msal?.account?.username,
            }
        }).then(() => {
            setNavLoading(false);
            setNavDate(null);
            notifications.update({
                id,
                color: 'teal',
                title: 'Saved new NAV for ' + fundID,
                message: 'Nice!  It is now ' + nav_per_unit + '',
                icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });

        }).catch((e) => {
            setNavLoading(false);
            setNavDate(null);
            console.log(e);
            notifications.update({
                id,
                color: 'red',
                title: 'Failed to save new NAV for ' + fundID,
                message: e.message,
                icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });

        });
    }
    const approveNav = (fundID: string, nav_per_unit: number, nav_date: Timestamp, updatedBy: string) => {

        const id = notifications.show({
            loading: true,
            title: 'Approving new NAV for ' + fundID + '...',
            message: 'Give it a minute...',
            autoClose: false,
            withCloseButton: false,
        });

        const fundRef = doc(db, "funds", fundID);
        updateDoc(fundRef, {
            "new_nav_awaiting_approval": deleteField(),
            "nav_per_unit": nav_per_unit,
            "nav_date": nav_date,
            "approvedBy": appContext?.msal?.account?.username,
            "updatedBy": appContext?.msal?.account?.username,

        }).then(() => {
            setNav(nav_per_unit);
            notifications.update({
                id,
                color: 'teal',
                title: 'Saved new NAV for ' + fundID,
                message: 'Nice!  It is now ' + nav_per_unit + '',
                icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });

        }).catch((e) => {
            console.log(e);
            notifications.update({
                id,
                color: 'red',
                title: 'Failed to save new NAV for ' + fundID,
                message: e.message,
                icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });

        });
    }
    const addDividend = (fundID: string, percentage: number, dividendDate: Timestamp, notes?: string) => {
        setDividendLoading(true)
        console.log("Is this on?");
        const id = notifications.show({
            loading: true,
            title: 'Adding new dividend for ' + fundID + '...',
            message: 'Give it a minute...',
            autoClose: false,
            withCloseButton: false,
        });

        const fundRef = doc(db, "funds", fundID);
        // Create a new dividend entry
        const newDividend = {
            percentage: percentage,
            date: dividendDate,
            updatedBy: appContext?.msal?.account?.username,
            notes: notes
        };
        if (!notes) {
            delete newDividend.notes
        }
        updateDoc(fundRef, {
            dividends: arrayUnion(newDividend),

        }).then(() => {
            setDividendLoading(false)
            setDividendPercentage('');
            setDividendDate(null);
            setDividendNotes('');
            notifications.update({
                id,
                color: 'teal',
                title: 'Added new Dividend for ' + fundID,
                message: 'Nice!  Latest Dividend is ' + percentage + '%',
                icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });

        }).catch((e) => {
            setDividendLoading(false)
            setDividendPercentage('');
            setDividendDate(null);
            setDividendNotes('');
            console.log(e);
            notifications.update({
                id,
                color: 'red',
                title: 'Failed to save new NAV for ' + fundID,
                message: e.message,
                icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
            });

        });
    }
    return (
        <Card shadow="sm" radius="md" withBorder mb="xs" p="xs">
            <Group mb="xs">
                <Title order={6}>{fund.name_en}</Title>
            </Group>
            <Divider mb="xs" />

            <Grid columns={6} gutter="xs">
                {/* NAV Update - 2 cells */}
                <Grid.Col span={2}>
                    <Card withBorder radius="sm" p="xs">
                        <Group grow>
                            <NumberInput
                                size="xs"
                                label="NAV per unit"
                                placeholder="Enter NAV"
                                value={nav}
                                onChange={setNav}
                            />
                            <DateInput
                                size="xs"
                                label="NAV Date"
                                firstDayOfWeek={0}
                                locale="en-gb"
                                dateParser={parseDayFirst}
                                placeholder="Select date"
                                valueFormat="DD/MM/YYYY"
                                value={navDate}
                                onChange={(value) => {
                                    if (value) {
                                        setNavDate(value);
                                    }
                                }}
                                getDayProps={(date) => ({
                                    disabled: date.getDay() !== fund.nav_update_day_of_week
                                })}
                            />
                        </Group>
                        <Button
                            size="xs"
                            variant="light"
                            color="blue"
                            fullWidth
                            mt={10}
                            disabled={!nav || !navDate || navLoading}
                            onClick={() => updateNav(fund.id, Number(nav), Timestamp.fromDate(navDate ?? new Date()))}
                        >
                            Update NAV
                        </Button>
                    </Card>
                </Grid.Col>

                    <Divider orientation="vertical" />

                {/* NAV Approval - 1 cells (used 0.97 to handle the dividers)*/}
                <Grid.Col span={0.97}>
                    {fund.new_nav_awaiting_approval ? (
                        <Card withBorder radius="sm" p="xs" bg="var(--mantine-color-orange-0)">
                            <Text size="xs" fw={500} c="orange">Pending Approval</Text>
                            <Text size="xs">NAV: {fund.new_nav_awaiting_approval.nav_per_unit} | Date: {dateFormater(fund.new_nav_awaiting_approval.nav_date.toDate())}</Text>
                            <Text size="xs" truncate>By: {fund.new_nav_awaiting_approval.updatedBy}</Text>
                            <Button
                                size="xs"
                                variant="light"
                                color="orange"
                                mt={15}
                                disabled={process.env.REACT_APP_ENV !== "development" && 
                                        fund.new_nav_awaiting_approval.updatedBy === appContext?.msal?.account?.username}
                                onClick={() => {
                                    approveNav(
                                        fund.id,
                                        Number(fund.new_nav_awaiting_approval!.nav_per_unit),
                                        fund.new_nav_awaiting_approval!.nav_date,
                                        fund.new_nav_awaiting_approval!.updatedBy
                                    );
                                }}
                            >
                                Approve
                            </Button>
                        </Card>
                    ) : (
                        <Text c="dimmed" size="xs" ta="center">No pending changes</Text>
                    )}
                </Grid.Col>

                <Divider orientation="vertical" />

                {/* Adding Dividend - 2 cells */}
                <Grid.Col span={3}>
                    <Card withBorder radius="sm" p="xs">
                        <Group grow>
                            <NumberInput
                                size="xs"
                                label="Dividend Percentage"
                                placeholder="Enter %"
                                value={dividendPercentage}
                                onChange={setDividendPercentage}
                                rightSection={<Text size="xs">%</Text>}
                            />
                            <DateInput
                                size="xs"
                                label="Date"
                                value={dividendDate}
                                firstDayOfWeek={0}
                                locale="en-gb"
                                dateParser={parseDayFirst}
                                placeholder="Select date"
                                valueFormat="DD/MM/YYYY"
                                onChange={(value) => {
                                    if (value) {
                                        setDividendDate(value);
                                    }
                                }}
                            />
                            <TextInput
                                size="xs"
                                label="Notes"
                                placeholder="Optional notes"
                                value={dividendNotes}
                                onChange={(e) => setDividendNotes(e.currentTarget.value)}
                            />
                        </Group>                            
                            <Button
                                size="xs"
                                variant="light"
                                color="green"
                            mt={10}
                                disabled={!dividendPercentage || !dividendDate || dividendLoading}
                                onClick={() => addDividend(
                                    fund.id, 
                                    Number(dividendPercentage), 
                                    Timestamp.fromDate(dividendDate ?? new Date()), 
                                    dividendNotes
                                )}
                            >
                                Add
                            </Button>
                    </Card>
                </Grid.Col>
               
            </Grid>
        </Card>
    );
};

export default FundManagementCard;